import { AuthoritiesGuard } from '@admin/app/core/auth/authorities.guard';
import { ReportsManagementActionsComponent } from '@admin/app/reports-management/reports-management-actions/reports-management-actions.component';
import { Routes } from '@angular/router';

export const REPORTS_MANAGEMENT_ROUTE: Routes = [
  {
    path: 'actions',
    component: ReportsManagementActionsComponent,
    data: {
      pageTitle: 'brandManagement.home.title',
      authorities: ['ROLE_ADMIN'],
    },
    canActivate: [AuthoritiesGuard],
  },
];
