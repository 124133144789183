import { ReportsManagementActionsComponent } from '@admin/app/reports-management/reports-management-actions/reports-management-actions.component';
import { REPORTS_MANAGEMENT_ROUTE } from '@admin/app/reports-management/reports-management.route';
import { AdminSharedModule } from '@admin/app/shared/shared.module';
import { QpLabelComponent } from '@library/components/qp-label/qp-label.component';
import { QpButtonDirective } from '@library/directives/qp-button/qp-button.directive';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { QimaInputTextareaModule } from '@qima/ngx-qima';

@NgModule({
  imports: [
    FormsModule,
    HttpClientModule,
    AdminSharedModule,
    RouterModule.forChild(REPORTS_MANAGEMENT_ROUTE),
    QpLabelComponent,
    QpButtonDirective,
    TranslateModule,
    QimaInputTextareaModule,
    ReportsManagementActionsComponent,
  ],
  exports: [RouterModule],
})
export class ReportsManagementModule {
  public constructor(private readonly _translateService: TranslateService) {
    this._translateService.use('en');
  }
}
